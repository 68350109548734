import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {HrDocumentThunks} from "features/hrDocument/hrDocumentThunks";

export interface HrDocumentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    hrDocuments?: any;
    pagy?: any;
    hrDocument?: any;
    isEditing?: boolean;
    activeTab?: string;
}

const initialState: HrDocumentState = {
    status: 'idle',
    error: null,
    hrDocuments: [],
    hrDocument: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false,
    activeTab: ''
};

const HrDocumentSlice = createSlice({
    name: 'hrdocument',
    initialState,
    reducers: {
        setHrDocument: (state, action) => {
            state.hrDocument = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(HrDocumentThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(HrDocumentThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.hrDocument = action.payload;
                state.hrDocuments = [...state.hrDocuments, action.payload];
                showToast('HrDocument created successfully', "success");
            })
            .addCase(HrDocumentThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(HrDocumentThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(HrDocumentThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedHrDocument = action.payload;
                state.hrDocument = updatedHrDocument;
                
                const index = state.hrDocuments.findIndex((item: any) => item.id === updatedHrDocument.id);
                if (index !== -1) {
                    state.hrDocuments[index] = updatedHrDocument;
                }
                showToast('HrDocument updated successfully', "success");
            })
            .addCase(HrDocumentThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(HrDocumentThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(HrDocumentThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.hrDocument = action.payload;
            })
            .addCase(HrDocumentThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(HrDocumentThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(HrDocumentThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.hrDocuments = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(HrDocumentThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(HrDocumentThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(HrDocumentThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.hrDocuments = state.hrDocuments.filter((item: any) => item.id !== action.payload);
                showToast('HrDocument deleted successfully', "success");
            })
            .addCase(HrDocumentThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setHrDocument,setActiveTab, setIsEditing} = HrDocumentSlice.actions;
export {initialState as hrDocumentInitialState};
export default HrDocumentSlice.reducer;
