// hooks/useAttachmentModal.tsx
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { AppDispatch } from 'store/store';

interface UseAttachmentModalProps<T> {
    openDrawer: () => void;
    fetchItemThunk: (id: string) => any;
}

const useAttachmentModal = <T,>({
                                    openDrawer,
                                    fetchItemThunk,
                                }: UseAttachmentModalProps<T>) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedAttachmentUrl, setSelectedAttachmentUrl] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const openModal = (attachmentUrl: string) => {
        setSelectedAttachmentUrl(attachmentUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedAttachmentUrl(null);
    };

    const handleNameClick = async (item: T & { id: string; status: string; attachmentUrl?: string }) => {
        if (item.status === 'Complete') {
            if (item.attachmentUrl) {
                openModal(item.attachmentUrl);
            } else {
                alert('No attachment available for this item.');
            }
        } else {
            await dispatch(fetchItemThunk(item.id));
            openDrawer();
        }
    };

    const AttachmentModal = () => {
        if (!isModalOpen) return null;

        return (
            <div
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                onClick={closeModal}
            >
                <div
                    className="relative bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="p-4 h-full overflow-auto">
                        <DocViewer
                            pluginRenderers={DocViewerRenderers}
                            documents={[{ uri: selectedAttachmentUrl || '' }]}
                            style={{ height: '80vh' }}
                            config={{ header: { disableHeader: true, disableFileName: true, retainURLParams: true } }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return {
        openModal,
        closeModal,
        AttachmentModal,
        handleNameClick,
    };
};

export default useAttachmentModal;
