import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import {UserBackgroundDocument} from "types/userBackgroundDocument";
import SubmitButton from "components/SubmitButton";
import PencilSquareIcon from "assets/images/icons/PencilSquareIcon";
import {UserBackgroundDocumentThunks} from "features/userBackgroundDocument/userBackgroundDocumentThunks";
import {setIsEditing} from "features/userBackgroundDocument/userBackgroundDocumentSlice";
import XIcon from "../../assets/images/icons/XIcon";
import DateInput from "../../components/inputs/DateInput";
import DescriptionInput from "../../components/inputs/DescriptionInput";
import RadioInput from "../../components/inputs/RadioInput";
import {Training} from "../../types/training";
import FileTextIcon from "../../assets/images/icons/FileTextIcon";
import TrashIcon from "../../assets/images/icons/TrashIcon";
import FileInput from "../../components/inputs/FileInput";
import {Credential} from "../../types/credential";
import {UserCredential} from "../../types/userCredential";

interface UserBackgroundDocumentFormProps {
    id?: string,
    closeDrawer: () => void,
    isDrawerOpen?: boolean
}

const UserBackgroundDocumentForm: React.FC<UserBackgroundDocumentFormProps> = ({id, closeDrawer, isDrawerOpen}) => {
    const userBackgroundDocument = useSelector((state: RootState) => state.userBackgroundDocument.userBackgroundDocument);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedUserBackgroundDocument, setUpdatedUserBackgroundDocument] = useState({...userBackgroundDocument});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    useEffect(() => {
        setUpdatedUserBackgroundDocument(userBackgroundDocument);
        setIsSaveDisabled(!userBackgroundDocument?.name);
    }, [userBackgroundDocument]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement| HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedUserBackgroundDocument({...updatedUserBackgroundDocument, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (userBackgroundDocument?.id) {
            await dispatch(UserBackgroundDocumentThunks.update(new UserBackgroundDocument(updatedUserBackgroundDocument))).then(() => closeDrawer());
        } else {
            await dispatch(UserBackgroundDocumentThunks.create(new UserBackgroundDocument(updatedUserBackgroundDocument))).then(() => closeDrawer());
        }
    };
    const handleSelectChange = (value: string) => {
        setUpdatedUserBackgroundDocument((prevState: Credential) => ({
            ...prevState,
           userBackgroundDocumentId: value,
        }));
        setIsSaveDisabled(!value);
    };

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const attachment = files[0];
            setUploadedFile(attachment);
            setUpdatedUserBackgroundDocument((prevState: UserCredential) => ({
                ...prevState,
                attachment: attachment,
            }));
        }
    };
    const handleClose = () => {
        dispatch(setIsEditing(false));
        closeDrawer();
    };

    const handleRadioChange = (field: string, value: boolean) => {
        setUpdatedUserBackgroundDocument((prevState: Training) => ({
            ...prevState,
            [field]: value,
        }));
    };
    const handleDelete = () => {
        setUploadedFile(null);
        setUpdatedUserBackgroundDocument((prevState: UserCredential) => ({
            ...prevState,
            attachment: null,
        }));
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div className="h-[158px] px-6 pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {`Upload ${userBackgroundDocument?.name}`}
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {`Add an employees required document so you can keep track of their status and remain compliant.`}
                        </div>
                    </div>
                    <button onClick={closeDrawer} className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>
                <div className="h-full px-6 pb-6  ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full ">
                                    {uploadedFile || userBackgroundDocument?.attachmentUrl ? (
                                        <div
                                            className=" w-full h-auto  p-5 bg-white rounded-md border border-slate-200 flex-col justify-start items-start inline-flex">
                                            <div
                                                className="self-stretch flex-col justify-start items-start gap-2.5 flex">
                                                <div
                                                    className="self-stretch justify-start items-center gap-2 inline-flex">
                                                    <div
                                                        className="w-[48px] h-[48px] bg-white rounded-lg border border-slate-200 flex-col justify-center items-center inline-flex">
                                                        <div className="w-6 h-6 mt-1 ml-1 text-slate-400">
                                                            <FileTextIcon/>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                        <div
                                                            className="self-stretch text-slate-800 text-base font-semibold font-['Inter'] leading-normal tracking-tight">
                                                            {uploadedFile?.name || userBackgroundDocument?.name}
                                                        </div>
                                                        <div
                                                            className="self-stretch text-slate-400 text-xs font-medium font-['Inter'] leading-none tracking-tight">
                                                            {(uploadedFile && uploadedFile.size !== undefined) ?
                                                                `${(uploadedFile.size / 1024).toFixed(2)} KB` :
                                                                userBackgroundDocument?.size ? `${(userBackgroundDocument.size / 1024).toFixed(2)} KB` : userBackgroundDocument.attachmentSize}
                                                        </div>
                                                    </div>
                                                    <div className="w-4 h-4 relative text-slate-400">
                                                        <button type="button" onClick={handleDelete}>
                                                            <TrashIcon/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <FileInput
                                            onFileChange={handleFileChange}
                                            label="Drop your files here or"
                                            labelBlue="browse"
                                            subtitle="Maximum size: 50MB"
                                        />
                                    )}
                                </div>
                                <div className="w-full">
                                    <TextInput
                                        id="name"
                                        type="text"
                                        label="Name"
                                        value={updatedUserBackgroundDocument?.name}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="w-full">
                                    <TextInput
                                        id="agency"
                                        type="text"
                                        label="Agency"
                                        value={updatedUserBackgroundDocument?.agency}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full">
                                    <DescriptionInput
                                        id="notes"
                                        type="text"
                                        label="Notes"
                                        value={updatedUserBackgroundDocument?.notes}
                                        placeholder=""
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="w-full">
                                    <DateInput
                                        id="expirationDate"
                                        type="text"
                                        label="Expiration date"
                                        value={updatedUserBackgroundDocument?.expirationDate}
                                        placeholder="MM/DD/YYYY"
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="w-full">
                                    <RadioInput
                                        label="Reminder"
                                        options={[
                                            {id: 'remindUserYes', label: 'Yes', value: true},
                                            {id: 'remindUserNo', label: 'No', value: false}
                                        ]}
                                        selectedValue={updatedUserBackgroundDocument?.remindUser}
                                        onChange={(value) =>
                                            handleRadioChange('remindUser', value)
                                        }


                                    />
                                </div>


                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-5 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-fulljustify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={(userBackgroundDocument.id ? 'Save' : 'Add document')}
                                          disabled={isSaveDisabled}
                            />
                        </div>
                        <div
                            className="flex w-full justify-center items-center gap-x-2 pb-4">
                            <button type="submit" onClick={handleClose}
                                    className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                                Never mind
                            </button>
                        </div>
                    </div>
                </footer>
            </div>
    );
};

export default UserBackgroundDocumentForm;
