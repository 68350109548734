import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {BackgroundDocumentThunks} from "./backgroundDocumentThunks";

export interface BackgroundDocumentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    backgroundDocuments?: any;
    refresh: number;
    pagy?: any;
    backgroundDocument?: any;
    isEditing?: boolean;
    activeTab?: string;
}

const initialState: BackgroundDocumentState = {
    status: 'idle',
    error: null,
    backgroundDocuments: [],
    refresh: 0,
    backgroundDocument: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false,
    activeTab: ''
};

const BackgroundDocumentSlice = createSlice({
    name: 'backgroundDocument',
    initialState,
    reducers: {
        setBackgroundDocument: (state, action) => {
            state.backgroundDocument = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(BackgroundDocumentThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(BackgroundDocumentThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.backgroundDocument = action.payload;
                state.refresh += 1;
                state.backgroundDocuments = [...state.backgroundDocuments, action.payload];
                showToast('BackgroundDocument created successfully', "success");
            })
            .addCase(BackgroundDocumentThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(BackgroundDocumentThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(BackgroundDocumentThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedBackgroundDocument = action.payload;
                state.backgroundDocument = updatedBackgroundDocument;

                const index = state.backgroundDocuments.findIndex((item: any) => item.id === updatedBackgroundDocument.id);
                if (index !== -1) {
                    state.backgroundDocuments[index] = updatedBackgroundDocument;
                }
                showToast('BackgroundDocument updated successfully', "success");
            })
            .addCase(BackgroundDocumentThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(BackgroundDocumentThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(BackgroundDocumentThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.backgroundDocument = action.payload;
            })
            .addCase(BackgroundDocumentThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(BackgroundDocumentThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(BackgroundDocumentThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.backgroundDocuments = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(BackgroundDocumentThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(BackgroundDocumentThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(BackgroundDocumentThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.refresh += 1;
                state.backgroundDocuments = state.backgroundDocuments.filter((item: any) => item.id !== action.payload);
                showToast('BackgroundDocument deleted successfully', "success");
            })
            .addCase(BackgroundDocumentThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setBackgroundDocument, setIsEditing, setActiveTab} = BackgroundDocumentSlice.actions;
export {initialState as backgroundDocumentInitialState};
export default BackgroundDocumentSlice.reducer;
