import React, {useState} from "react";
import Table from "components/table/Table";
import TableHeader from "components/table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {SearchType} from "types/search";
import {useNavigate, useParams} from "react-router-dom";
import {setActiveTab} from "features/hr/hrSlice";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import {UserTrainingThunks} from "features/usertraining/userTrainingThunks";
import {setIsEditing, setUserTraining} from "features/usertraining/userTrainingSlice";
import TrashIcon from "assets/images/icons/TrashIcon";
import TableRow from "components/table/TableRow";
import TableCell from "components/table/TableCell";
import CheckCircleIcon from "assets/images/icons/CheckCircleIcon";
import XCircleIcon from "assets/images/icons/XCircleIcon";
import DotsMenu from "components/DotsMenu";
import {UserTraining} from "types/userTraining";
import WhiteButton from "../../components/WhiteButton";
import DownloadIcon from "../../assets/images/icons/DownloadIcon";
import DocViewer, {DocViewerRenderers} from "react-doc-viewer";

const UserTrainingsTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const trainings = useSelector((state: RootState) => state.userTraining.userTrainings);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.userTraining.pagy);
    const loading = useSelector((state: RootState) => state.userTraining.status === 'loading');
    const params = useParams<{ userId: string }>();
    const {setDotsMenuIsOpen} = useDropdownActionMenu();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedAttachmentUrl, setSelectedAttachmentUrl] = useState<string | null>(null);

    interface VisibleColumnsType {
        name: boolean;
        licensingAuthority: boolean;
        status: boolean;
        expDate: boolean;
        remindUserAt: boolean;
        notes: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
        name: true,
        licensingAuthority: true,
        status: true,
        expDate: true,
        remindUserAt: true,
        notes: true,
    });

    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'Licensing Authority', value: 'licensingAuthority', isVisible: visibleColumns.licensingAuthority},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Exp Date', value: 'expDate', isVisible: visibleColumns.expDate},
        {label: 'Reminder', value: 'remindUserAt', isVisible: visibleColumns.remindUserAt},
        {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes},
    ];

    const fetchData = async ({
                                 page,
                                 search,
                                 sortField,
                                 sortDirection,
                                 rowsPerPage,
                             }: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string; value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number;
    }): Promise<void> => {
        const {userId} = params;

        search.where.user_id = {
            operator: '==',
            value: userId as string,
        };

        await dispatch(UserTrainingThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };


    const handleNameClick = async (training: UserTraining) => {
        if (training.status === 'Complete') {
            if (training.attachmentUrl) {
                console.log(training.attachmentUrl);
                setSelectedAttachmentUrl(training.attachmentUrl);
                setIsModalOpen(true);
            } else {
                alert("No attachment available for this training.");
            }
        } else {
            await dispatch(UserTrainingThunks.show(training.id));
            dispatch(setIsEditing(true));
            openDrawer();
        }
    };

    const handleDelete = async (id: string) => {
        await dispatch(UserTrainingThunks.delete(id));
        setDotsMenuIsOpen(null);
    };

    const handleEdit = async (id: string) => {
        await dispatch(UserTrainingThunks.show(id));
        dispatch(setIsEditing(true));
        openDrawer();
    };

    const handleDownload = (attachmentUrl: string) => {
        if (attachmentUrl) {
            navigate(attachmentUrl);
        }
    };

    const handleSelectDownload = () => {
        if (selectedAttachmentUrl) {
            window.open(selectedAttachmentUrl, '_blank');
        }
    };

    return (
        <>
            <Table
                tabs={['HR Documents', 'Certifications', 'Trainings', 'Background Documents']}
                activeTab={'Trainings'}
                fetchData={fetchData}
                setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
                pagy={pagy}
                recordsName={'Trainings'}
                loading={loading}
                columns={true}
                columnOptions={columnOptions}
                onColumnToggle={handleColumnToggle}
                blueButton={<WhiteButton onClick={handleSelectDownload} label={''} icon={<DownloadIcon/>}/>}
                searchByOptions={searchByOptions}
            >
                <thead>
                <tr>
                    <th scope="col" className="px-3 pt-3.5 flex justify-center">
                        <input
                            type="checkbox"
                            className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
                        />
                    </th>
                    {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                    {visibleColumns.licensingAuthority && <TableHeader label="Licensing Authority"/>}
                    {visibleColumns.status && <TableHeader label="Status"/>}
                    {visibleColumns.expDate && <TableHeader label="Exp Date"/>}
                    {visibleColumns.remindUserAt && <TableHeader label="Reminder"/>}
                    {visibleColumns.notes && <TableHeader label="Notes"/>}
                    <TableHeader label=""/>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                {trainings.map((training: UserTraining) => {
                    const userOptions = [
                        {
                            label: 'Edit',
                            onClick: () => handleEdit(training.id),
                            hoverClass: "hover:bg-cyan-100 ",
                        },
                        ...(training.status === 'Complete'
                            ? [
                                {
                                    label: 'Download',
                                    onClick: () => handleDownload(training.attachmentUrl || ''),
                                    hoverClass: "hover:bg-cyan-100",
                                },
                            ]
                            : []),
                        ...(training.status === 'Missing'
                            ? [
                                {
                                    label: 'Upload',
                                    onClick: () => handleEdit(training.id),
                                    hoverClass: "hover:bg-cyan-100 ",
                                },
                            ]
                            : []),
                        {
                            label: 'Delete',
                            onClick: () => handleDelete(training.id),
                            icon: <TrashIcon/>,
                            hoverClass: "hover:bg-red-100 hover:text-red-800",
                        },
                    ];
                    return (
                        <TableRow key={training.id}>
                            <td scope="col" className="pt-3.5 flex justify-center">
                                <input
                                    type="checkbox"
                                    className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                               dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </td>
                            {visibleColumns.name && (
                                <TableCell>
                                    <div className="relative group">
                                        <div className="w-full flex items-center gap-x-3">
                                            <div className="flex-grow">
                          <span
                              className="text-sm cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                              onClick={() => handleNameClick(training)}
                          >
                            {training.name}
                          </span>
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            )}
                            {visibleColumns.licensingAuthority && <TableCell>{training.licensingAuthority}</TableCell>}
                            {visibleColumns.status && (
                                <TableCell>
                    <span
                        className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                      ${training.status === 'Complete' ? 'bg-green-100 text-green-800' : ''}
                      ${training.status === 'Missing' ? 'bg-red-100 text-red-800' : ''}
                      `}
                    >
                      {training.status === 'Complete' && <CheckCircleIcon className="mr-2"/>}
                        {training.status === 'Missing' && <XCircleIcon className="mr-2"/>}
                        <div className="pl-1">{training.status}</div>
                    </span>
                                </TableCell>
                            )}
                            {visibleColumns.expDate && <TableCell>{training.expirationDate}</TableCell>}
                            {visibleColumns.remindUserAt && <TableCell>{training.remindUserAt || 'None'}</TableCell>}
                            {visibleColumns.notes && (
                                <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                                    <div style={{maxWidth: '150px'}}
                                         className="whitespace-nowrap overflow-hidden text-ellipsis">
                                        {training.notes}
                                    </div>
                                </TableCell>
                            )}

                            <TableCell>
                                <DotsMenu options={userOptions}/>
                            </TableCell>
                        </TableRow>
                    );
                })}
                </tbody>
            </Table>

            {isModalOpen && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    onClick={() => setIsModalOpen(false)}
                >
                    <div
                        className="relative bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="p-4 h-full overflow-auto">
                            <DocViewer
                                pluginRenderers={DocViewerRenderers}
                                documents={[{uri: selectedAttachmentUrl || ''}]}
                                style={{height: '80vh'}}
                                config={{header: {disableHeader: true, disableFileName: true, retainURLParams: true}}}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserTrainingsTable;
