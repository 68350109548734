import apiClient from "services/apiClient";
import {Training} from "types/training";
import {SearchType} from "types/search";
import {buildUrlWithParams} from "utils/apiUtils";

export class TrainingService {
    static async index(
        page?: number,
        search?: SearchType<{ [key: string]: any }>,
        sortField?: string,
        sortDirection?: string,
        rowsPerPage?: number
    ) {
        const url = buildUrlWithParams({
            baseUrl: 'api/v1/trainings',
            page: page || undefined,
            search: search || undefined,
            sortField: sortField || undefined,
            sortDirection: sortDirection || undefined,
            rowsPerPage: rowsPerPage || undefined,
        });

        const response = await apiClient.get(url);
        return response.data;
    }

    static async create(record: Training) {
        const response = await apiClient.post("api/v1/trainings", {training: record.toJson()});
        return response.data;
    }

    static async show(id: string) {
        const response = await apiClient.get(`api/v1/trainings/${id}`);
        return response.data;
    }

    static async update(record: Training) {
        const response = await apiClient.patch(`api/v1/trainings/${record.id}`, {training: record.toJson()});
        return response.data;
    }

    static async delete(id: string) {
        const response = await apiClient.delete(`api/v1/trainings/${id}`);
        if (response.status === 204) {
            return id;
        }
        return response.data;
    }
}
