export interface UserBackgroundDocumentInterface {
    id: string;
    name: string;
    agency: string;
    submittedAt: string;
    expirationDate: string;
    notes: string;
    remindUser: string;
    status?: string;
    attachment?: any;
    attachmentUrl?: string;
    attachmentSize?: number;
}

export class UserBackgroundDocument implements UserBackgroundDocumentInterface {
    id: string;
    name: string;
    agency: string;
    submittedAt: string;
    expirationDate: string;
    notes: string;
    remindUser: string;
    status?: string;
    attachment?: any;
    attachmentUrl?: string;
    attachmentSize?: number;

    constructor(data: Partial<UserBackgroundDocumentInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.agency = data.agency!;
        this.submittedAt = data.submittedAt!;
        this.expirationDate = data.expirationDate!;
        this.notes = data.notes!;
        this.remindUser = data.remindUser!;
        this.status = data.status;
        this.attachment = data.attachment;
        this.attachmentUrl = data.attachmentUrl;
        this.attachmentSize = data.attachmentSize;
    }

    toJson(): UserBackgroundDocumentInterface {
        return {
            id: this.id,
            name: this.name,
            agency: this.agency,
            submittedAt: this.submittedAt,
            expirationDate: this.expirationDate,
            notes: this.notes,
            remindUser: this.remindUser,
            attachment: this.attachment
        };
    }
}
