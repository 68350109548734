import React, {useState, useRef, useEffect} from "react";
import {format, addMonths, subMonths, isBefore} from "date-fns";
import CalendarIcon from "assets/images/icons/CalendarIcon";
import ChevronLeftIcon from "assets/images/icons/ChevronLeftIcon";
import ChevronRightIcon from "assets/images/icons/ChevronRightIcon";

interface DateInputProps {
    label?: string;
    id: string;
    error?: string;
    type: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    calendarAlignment?: 'left' | 'right';
    className?: string;
}

const DateInput: React.FC<DateInputProps> = ({
                                                 label,
                                                 id,
                                                 error,
                                                 value,
                                                 onChange,
                                                 placeholder,
                                                 calendarAlignment = 'right',
                                                 className = "w-full"

                                             }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
    const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const today = new Date();
    const monthDropdownRef = useRef<HTMLDivElement | null>(null);
    const yearDropdownRef = useRef<HTMLDivElement | null>(null);
    const calendarRef = useRef<HTMLDivElement | null>(null);
    const [dateError, setDateError] = useState('');

    useEffect(() => {
        setSelectedDate(null);
    }, [value]);

    const toggleMonthDropdown = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsMonthDropdownOpen(!isMonthDropdownOpen);
        setIsYearDropdownOpen(false);
    };

    const toggleYearDropdown = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsYearDropdownOpen(!isYearDropdownOpen);
        setIsMonthDropdownOpen(false);
    };

    const handleDateClick = (day: number) => {
        const newDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
        if (isBefore(newDate, today)) {
            setDateError('The selected date cannot be before today.');
        } else {
            setSelectedDate(newDate);
            setDateError('');
            onChange({target: {id: id, value: format(newDate, "MM/dd/yyyy")}} as React.ChangeEvent<HTMLInputElement>);
            setIsCalendarOpen(false);
        }
    };

    const handlePreviousMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const handleInputClick = () => {
        setIsCalendarOpen(!isCalendarOpen);
        setIsMonthDropdownOpen(false);
        setIsYearDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                calendarRef.current &&
                !calendarRef.current.contains(event.target as Node) &&
                monthDropdownRef.current &&
                !monthDropdownRef.current.contains(event.target as Node) &&
                yearDropdownRef.current &&
                !yearDropdownRef.current.contains(event.target as Node)
            ) {
                setIsCalendarOpen(false);
                setIsMonthDropdownOpen(false);
                setIsYearDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1).getDay();
    const days = [...Array(firstDayOfMonth).fill(null), ...Array.from({length: daysInMonth}, (_, i) => i + 1)];

    return (
        <div className="flex flex-col items-start gap-[0.625rem]">
            <div className="flex justify-between w-full">
            {label && (
                <label
                    htmlFor={id}
                    className="block text-slate-800 text-sm font-semibold dark:text-white"
                >
                    {label}
                </label>
            )}
            </div>
            <div className="relative w-full">
                <button
                    type="button"
                    className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-400"
                    onClick={handleInputClick}
                >
                    <CalendarIcon/>
                </button>
                <input
                    type="text"
                    id={id}
                    value={selectedDate ? format(selectedDate, "MM/dd/yyyy") : value}
                    onChange={onChange}
                    onClick={handleInputClick}
                    className={`py-2.5 px-3 pl-10  w-full block border border-slate-200 shadow rounded-lg text-sm  text-gray-500 disabled:bg-slate-100  disabled:text-slate-300 ${
                        error ? "border-red-500" : "border-slate-200"
                    } ${className}`}
                    placeholder={placeholder}
                    aria-describedby={`${id}-error`}
                />

                {isCalendarOpen && (
                    <div
                        className={`absolute bg-white border rounded-lg shadow-lg mt-2 w-80 z-20 ${calendarAlignment === 'left' ? 'right-0' : 'left-0'}`}
                        ref={calendarRef}
                    >
                        <div className="p-3 space-y-0.5">
                            <div className="grid grid-cols-5 items-center gap-x-3 mx-1.5 pb-3">
                                <div className="col-span-1">
                                    <button
                                        type="button"
                                        className="size-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full focus:outline-none"
                                        onClick={handlePreviousMonth}
                                        aria-label="Previous"
                                    >
                                        <ChevronLeftIcon/>
                                    </button>
                                </div>
                                <div className="col-span-3 flex justify-center items-center gap-x-1">
                                    <div className="relative" ref={monthDropdownRef}>
                                        <button
                                            onClick={toggleMonthDropdown}
                                            className="text-slate-800 text-base font-medium"
                                        >
                                            {format(currentMonth, "MMMM")}
                                        </button>

                                        {isMonthDropdownOpen && (
                                            <div
                                                className="absolute h-48 z-10 w-[140px] bg-white border rounded-xl shadow-lg overflow-y-scroll">
                                                {Array.from({length: 12}, (_, i) => (
                                                    <button
                                                        key={i}
                                                        type="button"
                                                        className="w-full py-1.5 px-3 text-left block text-sm text-gray-800 hover:bg-cyan-50"
                                                        onClick={() => {
                                                            setCurrentMonth(
                                                                new Date(
                                                                    currentMonth.getFullYear(),
                                                                    i,
                                                                    1
                                                                )
                                                            );
                                                            setIsMonthDropdownOpen(false);
                                                        }}
                                                    >
                                                        {format(new Date(2020, i, 1), "MMMM")}
                                                    </button>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    <div className="relative" ref={yearDropdownRef}>
                                        <button
                                            onClick={toggleYearDropdown}
                                            className="text-slate-800 text-l font-medium"
                                        >
                                            {currentMonth.getFullYear()}
                                        </button>

                                        {isYearDropdownOpen && (
                                            <div
                                                className="absolute h-48 z-10 w-[120px] bg-white border rounded-xl shadow-lg  overflow-y-scroll">
                                                {[...Array(20)].map((_, index) => {
                                                    const year = new Date().getFullYear() + index;
                                                    return (
                                                        <button
                                                            key={year}
                                                            type="button"
                                                            className="w-full py-1.5 px-3 text-left block text-sm text-gray-800 hover:bg-cyan-50"
                                                            onClick={() => {
                                                                setCurrentMonth(
                                                                    new Date(year, currentMonth.getMonth(), 1)
                                                                );
                                                                setIsYearDropdownOpen(false);
                                                            }}
                                                        >
                                                            {year}
                                                        </button>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-1 flex justify-end">
                                    <button
                                        type="button"
                                        className="size-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full focus:outline-none"
                                        onClick={handleNextMonth}
                                        aria-label="Next"
                                    >
                                        <ChevronRightIcon/>
                                    </button>
                                </div>
                            </div>

                            <div className="grid grid-cols-7 ">
                                {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map(day => (
                                    <span key={day} className="text-center text-sm text-gray-500 pb-2 ">
                                        {day}
                                    </span>
                                ))}
                                {days.map((day, index) => {
                                    const today = new Date();

                                    const currentDayDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);


                                    const isPastDay = currentDayDate < today;

                                    return (
                                        <button
                                            key={index}
                                            type="button"
                                            className={`m-px h-10 w-10 flex justify-center text-sm items-center rounded-full ${
                                                isPastDay
                                                    ? "text-slate-200"
                                                    : "text-slate-800"
                                            } disabled:text-slate-200 items-center rounded-full ${
                                                selectedDate && selectedDate.getDate() === day
                                                    ? "bg-cyan-400 text-white"
                                                    : "hover:bg-white hover:text-cyan-400 hover:border hover:border-cyan-400 text-gray-800"
                                            }`}
                                            disabled={!day}
                                            onClick={() => day && handleDateClick(day)}
                                        >
                                            {day}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {dateError && <p className="text-red-500 text-xs">{dateError}</p>}
        </div>
    );
};

export default DateInput;
