    import React, {useEffect, useState} from "react";
    import { ProgramThunks } from "features/program/programThunks";
    import {useDispatch, useSelector} from "react-redux";
    import {AppDispatch, RootState} from "store/store";
    import Drawer from "components/Drawer";
    import ProgramTable from "features/program/ProgramTable";
    import ProgramForm from "features/program/ProgramForm";
    import ProgramDetails from "features/program/ProgramDetails";

    const ProgramsPage: React.FC = () => {
        const [isDrawerOpen, setIsDrawerOpen] = useState(false);
        const openDrawer = () => setIsDrawerOpen(true);
        const closeDrawer = () => setIsDrawerOpen(false);
        const isEditing = useSelector((state: RootState) => state.program.isEditing);

        return (
            <div className="">
                <div className="relative overflow-hidden">
                    <div className="pb-6">
                        <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                            Programs
                        </div>
                        <div
                            className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                            Manage your company's programs
                        </div>
                    </div>
                    <div className="relative">
                        <ProgramTable openDrawer={openDrawer}/>
                        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                            {isEditing ? <ProgramForm closeDrawer={closeDrawer}/> :
                                <ProgramDetails onClose={closeDrawer}/>}
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    };

    export default ProgramsPage;
