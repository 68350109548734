import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {HrDocument} from "types/hrDocument";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {HrDocumentThunks} from "./hrDocumentThunks";
import {setIsEditing, setHrDocument,setActiveTab} from "features/hrDocument/hrDocumentSlice";
import BlueButton from "components/BlueButton";
import PlusIcon from "assets/images/icons/PlusIcon";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenu from "components/DotsMenu";
import CheckCircleIcon from "../../assets/images/icons/CheckCircleIcon";
import XCircleIcon from "../../assets/images/icons/XCircleIcon";
import TrashIcon from "../../assets/images/icons/TrashIcon";
import {BackgroundDocument} from "../../types/backgroundDocument";
import {BackgroundDocumentThunks} from "../backgroundDocument/backgroundDocumentThunks";

const HrDocumentTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const hrDocuments = useSelector((state: RootState) => state.hrDocument.hrDocuments);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.hrDocument.pagy);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();
    const activeTab = useSelector((state: RootState) => state.hrDocument.activeTab);

    const loading = useSelector((state: RootState) => state.hrDocument.status === 'loading');

    const [visibleColumns, setVisibleColumns] = useState({
        id: true,
        name: true,
        description: true,
        status: true,
    });

    interface VisibleColumnsType {
        id: boolean,
        name: boolean,
        description: boolean,
        status: boolean,
    }
    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'ID', value: 'id', isVisible: visibleColumns.id},
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Description', value: 'description', isVisible: visibleColumns.description}
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        await dispatch(HrDocumentThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        openDrawer();
        await dispatch(HrDocumentThunks.show(id));
    };

    const handleDeactivate = (hrDocumentId: string) => {
        const hrDocument = new HrDocument({id: hrDocumentId, status: 'inactive'})
        dispatch(HrDocumentThunks.update(hrDocument) as any);
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (hrDocumentId: string) => {
        const hrDocument = new HrDocument({id: hrDocumentId, status: 'active'})
        dispatch(HrDocumentThunks.update(hrDocument) as any);
        setDotsMenuIsOpen(null);
    };

    const handleOpenNewHrDocumentDrawer = () => {
        dispatch(setHrDocument({
            id: '',
            name: '',
            description: '',
            status: '',
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };
    const handleDelete = async (id: string) => {
        await dispatch(HrDocumentThunks.delete(id));
        setDotsMenuIsOpen(null);
    };

    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            recordsName={'Documents'}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive']}
            searchable={true}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            loading={loading}
            searchByOptions={searchByOptions}
            blueButton={<BlueButton onClick={handleOpenNewHrDocumentDrawer} label="" icon={<PlusIcon/>}/>}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                      dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.id && <TableHeader label="ID" sortBy="id"/>}
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {hrDocuments.map((hrDocument: HrDocument) => {
                const userOptions = [];
                userOptions.push({
                    label: "Edit",
                    onClick: handleOpenDrawer(hrDocument.id),
                    hoverClass: "hover:bg-cyan-100",
                });
                if (hrDocument.status === "Active") {
                    userOptions.push({
                        label: "Deactivate",
                        onClick: () => handleDeactivate(hrDocument.id),
                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                    });
                }
                if (hrDocument.status === "Inactive") {
                    userOptions.push({
                        label: "Activate",
                        onClick: () => handleReactivate(hrDocument.id),
                        hoverClass: "hover:bg-cyan-100",
                    });

                }
                userOptions.push({
                    label: "Delete",
                    onClick: () => handleDelete(hrDocument.id),
                    icon: <TrashIcon/>,
                    hoverClass: "hover:bg-red-100 hover:text-red-800",
                });

                return (
                    <TableRow
                        key={hrDocument.id}
                    >
                        <td scope="col" className="pt-3.5 flex justify-center">
                            <input
                                type="checkbox"
                                className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                       dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                onClick={(e) => e.stopPropagation()}/>
                        </td>
                        {visibleColumns.id && <TableCell>{hrDocument.id}</TableCell>}
                        {visibleColumns.name &&
                            <TableCell>
                                <div className=" relative group">
                                    <div className="w-full flex items-center gap-x-3">
                                        <div className="flex-grow">
                                        <span
                                            className="text-sm  cursor-pointer font-medium dark:text-neutral-200 underline text-cyan-600"
                                            onClick={handleOpenDrawer(hrDocument.id)}
                                        >
                                            {hrDocument.name}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </TableCell>}
                        {visibleColumns.status && (
                            <TableCell>
                            <span
                                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-28
                                    ${hrDocument.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {hrDocument.status === 'Active' ? <CheckCircleIcon className="mr-2"/> :
                                    <XCircleIcon className="mr-2"/>}

                                <div className="pl-1">
                                    {hrDocument.status === 'Active' ? hrDocument.status : 'Inactive'}
                                </div>
                            </span>
                            </TableCell>)}

                        {visibleColumns.description && (
                            <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                                <div style={{ maxWidth: '150px' }} className="whitespace-nowrap overflow-hidden text-ellipsis">
                                    {hrDocument.description}
                                </div>
                            </TableCell>
                        )}
                        <TableCell>
                            <DotsMenu options={userOptions}/>
                        </TableCell>
                    </TableRow>
                )
                }
            )}
            </tbody>
        </Table>
    );
};

export default HrDocumentTable;
