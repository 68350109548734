import {createAsyncThunk} from "@reduxjs/toolkit";
import {UserBackgroundDocument} from "types/userBackgroundDocument";
import {UserBackgroundDocumentService} from "services/userBackgroundDocumentService";
import {SearchType} from "types/search";

export class UserBackgroundDocumentThunks {
    static index = createAsyncThunk(
        'userBackgroundDocument/index',
        async ({page, search, sortField, sortDirection, rowsPerPage}: {
            page: number,
            search: SearchType<{ [key: string]: any }>,
            sortField: string,
            sortDirection: string,
            rowsPerPage: number
        }) => {
            return await UserBackgroundDocumentService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );

    static create = createAsyncThunk(
        'userBackgroundDocument/create',
        async (record: UserBackgroundDocument) => {
            return await UserBackgroundDocumentService.create(record);
        }
    );

    static show = createAsyncThunk(
        'userBackgroundDocument/show',
        async (id: string) => {
            return await UserBackgroundDocumentService.show(id);
        }
    );

    static update = createAsyncThunk(
        'userBackgroundDocument/update',
        async (record: UserBackgroundDocument) => {
            return await UserBackgroundDocumentService.update(record);
        }
    );

    static delete = createAsyncThunk(
        'userBackgroundDocument/delete',
        async (id: string) => {
            return await UserBackgroundDocumentService.delete(id);
        }
    );
}
