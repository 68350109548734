import {createAsyncThunk} from "@reduxjs/toolkit";
import {BackgroundDocument} from "types/backgroundDocument";
import {BackgroundDocumentService} from "services/backgroundDocumentService";
import {SearchType} from "types/search";

export class BackgroundDocumentThunks {
    static index = createAsyncThunk(
        'backgroundDocument/index',
        async ({page, search, sortField, sortDirection, rowsPerPage}: {
            page: number,
            search: SearchType<{ [key: string]: any }>,
            sortField: string,
            sortDirection: string,
            rowsPerPage: number
        }) => {
            return await BackgroundDocumentService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );

    static create = createAsyncThunk(
        'backgroundDocument/create',
        async (record: BackgroundDocument) => {
            return await BackgroundDocumentService.create(record);
        }
    );

    static show = createAsyncThunk(
        'backgroundDocument/show',
        async (id: string) => {
            return await BackgroundDocumentService.show(id);
        }
    );

    static update = createAsyncThunk(
        'backgroundDocument/update',
        async (record: BackgroundDocument) => {
            return await BackgroundDocumentService.update(record);
        }
    );

    static delete = createAsyncThunk(
        'backgroundDocument/delete',
        async (id: string) => {
            return await BackgroundDocumentService.delete(id);
        }
    );
}
