import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import SubmitButton from "components/SubmitButton";
import DescriptionInput from "components/inputs/DescriptionInput";
import {UserHrDocumentThunks} from "features/userHrDocument/userHrDocumentThunks";
import {UserHrDocument} from "types/userHrDocument";
import DateInput from "components/inputs/DateInput";
import FileInput from "components/inputs/FileInput";
import TrashIcon from "assets/images/icons/TrashIcon";
import FileTextIcon from "assets/images/icons/FileTextIcon";
import CustomSelect from "components/inputs/CustomSelect";
import XIcon from "../../assets/images/icons/XIcon";

interface UserHrDocumentFormProps {
    id?: string;
    closeDrawer: () => void;
    isDrawerOpen: boolean;
}

const UserHrDocumentForm: React.FC<UserHrDocumentFormProps> = ({id, closeDrawer, isDrawerOpen}) => {
    const userHrDocument = useSelector((state: RootState) => state.userHrDocument.userHrDocument);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedUserHrDocument, setUpdatedUserHrDocument] = useState(userHrDocument);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null); // New state for file
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid =
            (uploadedFile !== null || userHrDocument?.attachmentUrl)

        setIsSaveDisabled(!isFormValid);
    }, [uploadedFile, updatedUserHrDocument]);

    useEffect(() => {
        setUpdatedUserHrDocument(userHrDocument);
        setIsSaveDisabled(!userHrDocument?.name);
    }, [userHrDocument]);

    useEffect(() => {
        if (!isDrawerOpen)
            handleClose()
    }, [isDrawerOpen]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedUserHrDocument({...updatedUserHrDocument, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (userHrDocument?.id) {
            await dispatch(UserHrDocumentThunks.update(new UserHrDocument(updatedUserHrDocument))).then(() => closeDrawer());
        } else {
            await dispatch(UserHrDocumentThunks.create(new UserHrDocument(updatedUserHrDocument))).then(() => closeDrawer());
        }
    };

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const attachment = files[0];
            setUploadedFile(attachment);
            setUpdatedUserHrDocument((prevState: UserHrDocument) => ({
                ...prevState,
                attachment: attachment,
            }));
        }
    };

    const handleClose = () => {
        setUpdatedUserHrDocument({
            id: '',
            notes: '',
            attachment: null,
        })

        setUploadedFile(null);
        setIsSaveDisabled(true);
    };

    const handleDelete = () => {
        setUploadedFile(null);
        setUpdatedUserHrDocument((prevState: UserHrDocument) => ({
            ...prevState,
            attachment: null,
        }));
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-[158px] px-6 pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {`Upload ${userHrDocument?.name}`}
                        </div>

                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {`Use the form below to upload the employee’s ${userHrDocument?.name} and complete this requirement.`}
                        </div>
                    </div>
                    <button onClick={closeDrawer} className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon/>
                    </button>
                </div>
                <div className="h-full px-6 pb-6 ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full ">
                                    {uploadedFile || userHrDocument?.attachmentUrl ? (
                                        <div
                                            className=" w-full h-auto  p-5 bg-white rounded-md border border-slate-200 flex-col justify-start items-start inline-flex">
                                            <div
                                                className="self-stretch flex-col justify-start items-start gap-2.5 flex">
                                                <div
                                                    className="self-stretch justify-start items-center gap-2 inline-flex">
                                                    <div
                                                        className="w-[48px] h-[48px] bg-white rounded-lg border border-slate-200 flex-col justify-center items-center inline-flex">
                                                        <div className="w-6 h-6 mt-1 ml-1 text-slate-400">
                                                            <FileTextIcon/>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                        <div
                                                            className="self-stretch text-slate-800 text-base font-semibold font-['Inter'] leading-normal tracking-tight">
                                                            {uploadedFile?.name || userHrDocument?.name}
                                                        </div>
                                                        <div
                                                            className="self-stretch text-slate-400 text-xs font-medium font-['Inter'] leading-none tracking-tight">
                                                            {(uploadedFile && uploadedFile.size !== undefined) ?
                                                                `${(uploadedFile.size / 1024).toFixed(2)} KB` :
                                                                userHrDocument?.size ? `${(userHrDocument.size / 1024).toFixed(2)} KB` : userHrDocument.attachmentSize}
                                                        </div>
                                                    </div>
                                                    <div className="w-4 h-4 relative text-slate-400">
                                                        <button type="button" onClick={handleDelete}>
                                                            <TrashIcon/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <FileInput
                                            onFileChange={handleFileChange}
                                            label="Drop your files here or"
                                            labelBlue="browse"
                                            subtitle="Maximum size: 50MB"
                                        />
                                    )}
                                </div>

                                <div className="w-full">
                                    <DescriptionInput
                                        id="notes"
                                        type="text"
                                        label="Notes"
                                        value={updatedUserHrDocument?.notes}
                                        placeholder="Notes"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={'Upload document'}
                                      disabled={isSaveDisabled}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={handleClose}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default UserHrDocumentForm;
