    import apiClient from "services/apiClient";
    import {UserHrDocument} from "types/userHrDocument";
    import {SearchType} from "types/search";
    import {buildUrlWithParams} from "utils/apiUtils";
    
    export class UserHrDocumentService {
        static async index(page: number, search: SearchType<{ [key: string]: any}>, sortField: string, sortDirection: string, rowsPerPage: number) {
            const url = buildUrlWithParams({
                baseUrl: 'api/v1/user_hr_documents',
                page: page,
                search: search,
                sortField: sortField,
                sortDirection: sortDirection,
                rowsPerPage: rowsPerPage,
            });

            const response = await apiClient.get(url);
            return response.data;
        }
    
        static async create(record: UserHrDocument) {
            const response = await apiClient.post("api/v1/user_hr_documents", {userHrDocument: record.toJson()},
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            return response.data;
        }
    
        static async show(id: string) {
            const response = await apiClient.get(`api/v1/user_hr_documents/${id}`);
            return response.data;
        }
    
        static async update(record: UserHrDocument) {
            const response = await apiClient.patch(`api/v1/user_hr_documents/${record.id}`, {userHrDocument: record.toJson()},
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        }
    
        static async delete(id: string) {
            const response = await apiClient.delete(`api/v1/user_hr_documents/${id}`);
            if (response.status === 204) {
                return id;
            }
            return response.data;
        }
    }
