import apiClient from "services/apiClient";
import {UserBackgroundDocument} from "types/userBackgroundDocument";
import {SearchType} from "types/search";
import {buildUrlWithParams} from "utils/apiUtils";

export class UserBackgroundDocumentService {
    static async index(page: number, search: SearchType<{
        [key: string]: any
    }>, sortField: string, sortDirection: string, rowsPerPage: number) {
        const url = buildUrlWithParams({
            baseUrl: 'api/v1/user_background_documents',
            page: page,
            search: search,
            sortField: sortField,
            sortDirection: sortDirection,
            rowsPerPage: rowsPerPage,
        });

        const response = await apiClient.get(url);
        return response.data;
    }

    static async create(record: UserBackgroundDocument) {
        const response = await apiClient.post("api/v1/user_background_documents", {userBackgroundDocument: record.toJson()},
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        return response.data;
    }

    static async show(id: string) {
        const response = await apiClient.get(`api/v1/user_background_documents/${id}`);
        return response.data;
    }

    static async update(record: UserBackgroundDocument) {
        const response = await apiClient.patch(`api/v1/user_background_documents/${record.id}`, {userBackgroundDocument: record.toJson()},
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        return response.data;
    }

    static async delete(id: string) {
        const response = await apiClient.delete(`api/v1/user_background_documents/${id}`);
        if (response.status === 204) {
            return id;
        }
        return response.data;
    }
}
