import apiClient from "services/apiClient";
import {User} from "types/user";
import {toSnakeCaseString} from "utils/caseConverter";
import {SearchType} from "types/search";
import {buildUrlWithParams, toQueryString} from "utils/apiUtils";

export class UserService {

    static async create(user: User) {
        const response = await apiClient.post(`api/v1/users`, {user: user.toJson()});
        return response.data;
    }

    static async register

    (user: User, token: string, password: string, pinCode: string, signature: string, credentials: any) {

        const isCredentialsObject = credentials && typeof credentials === 'object' && !Array.isArray(credentials);

        const credentialsArray = isCredentialsObject
            ? Object.keys(credentials).map(credentialId => ({
                credentialId: credentialId,
                expirationDate: credentials[credentialId]
            }))
            : [];

        const params = {
            user: {
                email: user.email,
                invitationToken: token,
                password: password,
                pinCode: pinCode,
                signature: signature,
                credentials: credentialsArray
            }
        }
        const response = await apiClient.post("api/v1/signup", params);
        return response.data;
    }
    static async index(
        page?: number,
        search?: SearchType<{ [key: string]: any }>,
        sortField?: string,
        sortDirection?: string,
        rowsPerPage?: number
    ) {
        const url = buildUrlWithParams({
            baseUrl: 'api/v1/users',
            page: page || undefined,
            search: search || undefined,
            sortField: sortField || undefined,
            sortDirection: sortDirection || undefined,
            rowsPerPage: rowsPerPage || undefined,
        });

        const response = await apiClient.get(url);
        return response.data;
    }
    static async show(id: string) {
        const response = await apiClient.get(`api/v1/users/${id}`);
        return response.data;
    }
    static async reactivate(id: string) {
        const response = await apiClient.patch(`api/v1/users/${id}/reactivate`);
        return response.data;
    }
    static async deactivate(id: string) {
        const response = await apiClient.patch(`api/v1/users/${id}/deactivate`);
        return response.data;
    }
    static async unlock(id: string) {
        const response = await apiClient.patch(`api/v1/users/${id}/unlock`);
        return response.data;
    }
    static async unblock(id: string) {
        const response = await apiClient.patch(`api/v1/users/${id}/unblock`);
        return response.data;
    }
    static async update(user: User) {
        const response = await apiClient.patch(`api/v1/users/${user.id}`, {user: user.toJson()});
        return response.data;
    }
    static async delete(id: string) {
        const response = await apiClient.delete(`api/v1/users/${id}`);
        if (response.status === 204) {
            return id;
        }
        return response.data;
    }
}





