import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {UserBackgroundDocumentThunks} from "./userBackgroundDocumentThunks";

export interface UserBackgroundDocumentState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userBackgroundDocuments?: any;
    refresh: number;
    pagy?: any;
    userBackgroundDocument?: any;
    isEditing?: boolean;

}

const initialState: UserBackgroundDocumentState = {
    status: 'idle',
    error: null,
    userBackgroundDocuments: [],
    refresh: 0,
    userBackgroundDocument: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false,

};

const UserBackgroundDocumentSlice = createSlice({
    name: 'userBackgroundDocument',
    initialState,
    reducers: {
        setUserBackgroundDocument: (state, action) => {
            state.userBackgroundDocument = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(UserBackgroundDocumentThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserBackgroundDocumentThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userBackgroundDocument = action.payload;
                state.userBackgroundDocuments = [...state.userBackgroundDocuments, action.payload];
                showToast('UserBackgroundDocument created successfully', "success");
            })
            .addCase(UserBackgroundDocumentThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(UserBackgroundDocumentThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserBackgroundDocumentThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedUserBackgroundDocument = action.payload;
                state.userBackgroundDocument = updatedUserBackgroundDocument;

                const index = state.userBackgroundDocuments.findIndex((item: any) => item.id === updatedUserBackgroundDocument.id);
                if (index !== -1) {
                    state.userBackgroundDocuments[index] = updatedUserBackgroundDocument;
                }
                showToast('UserBackgroundDocument updated successfully', "success");
            })
            .addCase(UserBackgroundDocumentThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(UserBackgroundDocumentThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserBackgroundDocumentThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userBackgroundDocument = action.payload;
            })
            .addCase(UserBackgroundDocumentThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(UserBackgroundDocumentThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserBackgroundDocumentThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userBackgroundDocuments = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(UserBackgroundDocumentThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(UserBackgroundDocumentThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserBackgroundDocumentThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userBackgroundDocuments = state.userBackgroundDocuments.filter((item: any) => item.id !== action.payload);
                showToast('UserBackgroundDocument deleted successfully', "success");
            })
            .addCase(UserBackgroundDocumentThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setUserBackgroundDocument, setIsEditing} = UserBackgroundDocumentSlice.actions;
export {initialState as userBackgroundDocumentInitialState};
export default UserBackgroundDocumentSlice.reducer;
