import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "store/store";
import Drawer from "components/Drawer";
import TrainingTable from "features/training/TrainingTable";
import TrainingForm from "features/training/TrainingForm";


const TrainingsPage: React.FC = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    return (
        <div className="">
            <div className="relative overflow-hidden">
                <div className="pb-6">
                    <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                        Trainings
                    </div>
                    <div
                        className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                        Manage your company's trainings
                    </div>
                </div>
                <div>
                    <div>
                        <div className="relative">
                            <TrainingTable openDrawer={openDrawer}/>
                            <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                                <TrainingForm closeDrawer={closeDrawer}/>
                            </Drawer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainingsPage;
