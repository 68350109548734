import apiClient from "services/apiClient";
import {UserCredential} from "types/userCredential";
import {SearchType} from "types/search";
import {buildUrlWithParams} from "utils/apiUtils";

export class UserCredentialService {
    static async index(page: number, search: SearchType<{
        [key: string]: any
    }>, sortField: string, sortDirection: string, rowsPerPage: number) {
        const url = buildUrlWithParams({
            baseUrl: 'api/v1/user_credentials',
            page: page,
            search: search,
            sortField: sortField,
            sortDirection: sortDirection,
            rowsPerPage: rowsPerPage,
        });

        const response = await apiClient.get(url);
        return response.data;
    }

    static async create(record: UserCredential) {
        const response = await apiClient.post("api/v1/user_credentials", {userCredential: record.toJson()},
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        return response.data;
    }

    static async show(id: string) {
        const response = await apiClient.get(`api/v1/user_credentials/${id}`);
        return response.data;
    }

    static async update(record: UserCredential) {
        const response = await apiClient.patch(`api/v1/user_credentials/${record.id}`, {userCredential: record.toJson()},
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        );
        return response.data;
    }

    static async delete(id: string) {
        const response = await apiClient.delete(`api/v1/user_credentials/${id}`);
        if (response.status === 204) {
            return id;
        }
        return response.data;
    }
}
